import React, {useEffect, useState} from 'react'
import ReactHtmlParser from "react-html-parser";
import {graphql, useStaticQuery} from "gatsby";
import {Box} from "@chakra-ui/react"
import '../../styles/contact-quote.css'
import './static-pages.css'


const PagesContent = (props) => {


    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll)
    }, []);


    const pagesBody = useStaticQuery(graphql`
            query {
        allWpPage{
          edges {
            node {
              title
              content
            }
          }
        }
      }
    `)

    return (
        <Box p={{base: 3, md: 5, lg: 10}}>
            {
                pagesBody.allWpPage.edges.map((page, index) => {
                    if (page.node.title === props.page_name) {
                        return (
                            <Box>
                                <h1>{page.node.title}</h1>
                                {ReactHtmlParser(page.node.content)}
                            </Box>
                        )
                    }
                })
            }

            <div className="pages-parallax-a">
                <img className="parallax-a"
                     src={`https://${process.env.GATSBY_CURRENT_URL}/wp-content/uploads/2021/12/parralax_a.png`}
                     style={{transform: `translateY(${offsetY * 0.2}px)`}}/>
            </div>
            <div className="pages-parallax-b">
                <img className="parallax-b"
                     src={`https://${process.env.GATSBY_CURRENT_URL}/wp-content/uploads/2021/12/parralax_b.png`}
                     style={{transform: `translateY(${offsetY * 0.2}px)`}}/>
            </div>
        </Box>
    )


}

export default PagesContent